$width: 1280px;
$height: 720px;
$primary: #253754;
$serif: lora, sans-serif;
$sansSerif: poppins, serif;

@page {
  size: $width $height;
  margin: 0;
}

.page {
  width: $width;
  height: $height;
  padding: 2em;
  font-family: $sansSerif;
  -webkit-print-color-adjust: exact;
}

.page1 {
  padding: 0;

  .wrapper {
    position: relative;

    img {
      position: absolute;
    }

    h1 {
      position: absolute;
      top: 265px;
      left: 90px;
      color: white;
      font-size: 44px;
      font-family: $serif;
      font-weight: bold;
    }

    h2 {
      position: absolute;
      top: 335px;
      left: 90px;
      color: white;
      font-size: 28px;
      width: 500px;
      font-family: $sansSerif;
      font-weight: bold;
    }

    .logo {
      width: 150px;
      top: 60px;
      left: 80px;
    }
  }
}

.page2 {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    .card {
      width: 500px;
      height: 400px;
      border: 3px solid $primary;
      border-radius: 20px;

      h2 {
        background-color: white;
        position: relative;
        top: -80px;
        left: -20px;
        display: inline-block;
        padding: 0.5em;
        font-size: 32px;
        font-weight: bold;
        color: $primary;
      }

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        padding: 1em;
        margin-top: -125px;

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            font-size: 28px;
            width: 130px;
          }

          .right {
            width: 240px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.page3 {
  padding: 0;
  position: relative;

  h1 {
    position: absolute;
    width: 520px;
    font-size: 26px;
    top: 200px;
    left: 700px;
  }

  h2 {
    position: absolute;
    width: 50%;
    font-size: 26px;
    top: 400px;
    left: 700px;
    width: 500px;
    font-weight: bold;
  }
}

.page4 {
  padding: 0;

  .wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;

    .left {
      background-color: #faf7f3;
      width: 400px;
      height: 100%;
      padding: 40px;
      h1 {
        color: #999;
      }
      .brandName {
        margin-top: 1em;
        font-size: 30px;
        text-decoration: underline;
        font-weight: bold;
        color: $primary;
      }
      .brandLocation {
        margin-top: 0;
        margin-bottom: 2em;
      }
      p {
        margin-top: 1em;
      }
    }

    .right {
      flex-grow: 1;
      flex-basis: 0;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .top {
        gap: 10px;
        display: flex;
        .tile {
          flex-grow: 1;
          flex-basis: 0;
        }
        img {
          width: 300px;
          height: 350px;
          object-fit: cover;
        }
        h2 {
          margin-top: 0.5em;
          font-size: 18px;
          font-weight: bold;
        }
        .msrp {
          color: #999;
        }
      }

      .bottom {
        gap: 10px;
        display: flex;
        margin-top: 20px;
        justify-content: space-evenly;

        .tile {
          height: 100%;
          img {
            width: 160px;
            height: 150px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.page5 {
  padding: 0;
  position: relative;

  h1 {
    position: absolute;
    font-size: 35px;
    top: 50px;
    left: 50px;
    width: 350px;
    line-height: 1.2em;
    font-weight: bold;
    font-family: $serif;
    color: $primary;
  }

  h2 {
    position: absolute;
    width: 50%;
    font-size: 24px;
    top: 200px;
    left: 50px;
    width: 400px;
    line-height: 1.2em;
    color: $primary;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 50px;
    padding-top: 20px;
    padding-bottom: 0;
    gap: 40px;
    position: relative;

    li {
      flex-grow: 1;
      flex-basis: 0;
      font-size: 14px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        &:after {
          content: "";
          width: 60px;
          height: 3px;
          display: block;
          background-color: #c39d66;
          margin-top: 0.5em;
          margin-bottom: 0.5em;
        }
      }

      .founder {
        img {
          width: 80px;
          float: left;
          margin-right: 1em;
          border-radius: 10px;
        }
      }
    }
  }
}

.page6 {
  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}

.choose-product {
  .card {
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px;

    &:hover {
      border: 1px solid #999;
    }

    img {
      height: 100px;
      object-fit: cover;
      margin-bottom: 10px;
    }
  }
}

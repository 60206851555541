[class$="-control"] {
  border-radius: 4px;
  border: 1px solid #ccccd1;
  min-height: 32px;
  box-shadow: none;
}

[class*="__single-value"] {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #33333a;
  padding: 10px 8px;
  margin: 0;
}

[class*="value-container"] {
  margin: 0;
  padding: 0;
}

[class*="input-container"] {
  margin: 0;
  padding: 0 0 0 8px;
}

[class$="-menu"] {
  border-radius: 6px;
  border: 1px solid #efeff2;
  box-shadow: 0 0 20px rgb(0 0 0 / 12%);
  margin-top: 4px;
  margin-bottom: 0;
}

.option[class*="-option"] {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #33333a;
  background-color: #fff;
}

.option[class*="-option"]:hover {
  background-color: #f5f5f5;
}

[class*="menu-list"] {
  padding: 10px 0;
}

[class*="control--menu-is-open"] {
  border-color: #5d82a4;
}

[class*="control--is-focused"] {
  border-color: #ccccd1;
}

[class*="control--is-focused"]:hover {
  border-color: #ccccd1;
}

[class*="is-selected"] {
  background-color: #f5f5f5 !important;
}

.custom-select[class$="-placeholder"] {
  color: #ccccd1;
  padding: 10px 8px;
  line-height: 20px;
  margin: 0;
}

[class*="select__multi-value"] {
  margin-left: 8px !important;
  background: #f3f3f4 !important;
  font-size: 13px !important;
  margin: 8px 0 8px 8px !important;
  border-radius: 4px !important;
}

[class*="select__multi-value__label"] {
  font-style: normal !important;
  align-items: center !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  margin: 2px 0 !important;
  text-align: center !important;
  display: flex !important;
  padding: 0 !important;
  padding-left: 4px !important;
}

[class*="select__multi-value__remove"] {
  background-color: transparent !important;
  color: #33333a !important;
  cursor: pointer !important;
  margin: 0 !important;
  margin-left: 4px !important;
}

[class*="select__multi-value__remove"]:hover {
  background-color: transparent !important;
}

[class*="__group-heading"] > [class*="-group"] {
  text-transform: capitalize;
  color: #33333a;
  margin: 0;
}

[class*="-is-disabled"] {
  background-color: #f3f3f4;
  border: 0;
  color: #ccccd1;
  cursor: not-allowed;
}

[class$="-control"] .invalid {
  border: 1px solid #fe7475;
  background: #ed55651a;
}

.group[class*="select__group"] {
  color: #33333a;
  font-weight: 600;
  margin: 0 8px;
}

[class*="select__group"]:not(:first-child) {
  border-top: 1px solid #ccccd1;
}

[class*="select__option"] .checkbox {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  cursor: pointer;
  color: "#fff";
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid #ccccd1;
  vertical-align: middle;
  text-align: middle;
}

[class*="select__option"] .checkbox:checked {
  background-color: #5d82a4;
  border-color: #5d82a4;
  position: relative;
}

[class*="select__option"] .checkbox:checked::after {
  content: "";
  width: 4px;
  height: 7px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 5px;
  top: 3px;
}

@media print {
  .page:not(:last-child) {
    page-break-after: always;
  }

  .hidePrint {
    display: none !important;
  }

  .tabPanels {
    margin: 0 !important;
    padding: 0 !important;
  }

  .tabPanel {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.page {
  @media print {
    border: 0px solid #ccc;
  }
  @media screen {
    margin: 0 auto;
    margin-bottom: 1em;
    border: 1px solid #ccc;
  }
}

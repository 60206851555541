.imgWrapperClass {
  width: 100%;
  height: 100%;
  .imgClass {
    width: 70% !important;
    height: 70% !important;
    object-fit: contain;
    margin: auto;
    margin-top: 50px;
  }
}

.PhotoView__PhotoBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: none !important;
}

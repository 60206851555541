$borderRadius: 12px;
.card-ai {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 2px;
  border-radius: $borderRadius !important;
  background-image: linear-gradient(
    140deg,
    rgb(255, 193, 112) 0%,
    rgb(254, 160, 72) 26%,
    rgb(255, 127, 36) 39%,
    rgb(255, 81, 0) 50%,
    rgb(255, 87, 137) 61%,
    rgb(245, 137, 220) 74%,
    rgb(193, 184, 255) 100%
  );

  .card-body-ai {
    padding: 15px;
    background-color: white;
    border-radius: $borderRadius - 2;
  }
}

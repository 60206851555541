@import "react-calendar/dist/Calendar.css";

.react-calendar {
  font-family: var(--chakra-fonts-body);
  font-weight: var(--chakra-fontWeights-normal);
  font-style: normal;
  border: none;
}

/*
NAVIGATION DESIGN
*/
.react-calendar__navigation {
  padding: var(--chakra-space-4) var(--chakra-space-4) var(--chakra-space-2);
  margin: 0;
  height: auto;
}

.react-calendar--doubleView .react-calendar__navigation {
  padding: var(--chakra-space-4) var(--chakra-space-4) var(--chakra-space-2);
  margin: 0;
}

.react-calendar__navigation button {
  font-size: var(--chakra-fontSizes-lg);
  font-weight: var(--chakra-fontWeights-semibold);
  color: var(--chakra-colors-grey-800);
  min-width: var(--chakra-space-8);
  border-radius: var(--chakra-radii-base);
}

.react-calendar__navigation__arrow {
  height: var(--chakra-space-8);
}

.react-calendar__month-view {
  padding: 0px var(--chakra-space-4) var(--chakra-space-4) var(--chakra-space-4);
}
.react-calendar--doubleView .react-calendar__month-view:first-child {
  padding: 0px var(--chakra-space-2) var(--chakra-space-4) var(--chakra-space-4);
}
.react-calendar--doubleView .react-calendar__month-view:last-child {
  padding: 0px var(--chakra-space-4) var(--chakra-space-4) var(--chakra-space-2);
}
.react-calendar__navigation button:enabled:focus {
  background: white;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:disabled:hover {
  background: var(--chakra-colors-greyAlpha-100);
}

.react-calendar__navigation button:disabled {
  background: white;
}
.react-calendar--doubleView .react-calendar__navigation__label {
  display: flex;
}
.react-calendar--doubleView .react-calendar__navigation__label__labelText {
  padding-top: var(--chakra-space-1-5);
}

.react-calendar__navigation__label {
  display: inline-block;
  justify-content: space-around;
  gap: var(--chakra-space-24);
}

.react-calendar__navigation__label__divider,
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

/*
DAYS DESIGN
*/
.react-calendar__month-view__weekdays {
  font-size: var(--chakra-fontSizes-sm);
  font-weight: var(--chakra-fontWeights-normal);
  line-height: var(--chakra-lineHeights-tall);
  color: var(--chakra-colors-grey-600);
}

.react-calendar__month-view__weekdays__weekday {
  width: var(--chakra-space-8);
  height: var(--chakra-space-8);
}

.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
  text-transform: none;
}

.react-calendar__month-view__days,
.react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ):not(.react-calendar__tile--rangeBothEnds):not(
    .react-calendar__tile--rangeStart
  ):not(.react-calendar__tile--rangeEnd):not(.react-calendar__tile--active):not(
    .react-calendar__tile--hover
  ) {
  font-size: var(--chakra-fontSizes-sm);
  color: var(--chakra-colors-grey-900) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--chakra-colors-grey-900);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--chakra-colors-grey-500);
}
.react-calendar__tile {
  display: block;
  width: var(--chakra-space-8);
  height: var(--chakra-space-8);
  padding: 0;
}
.react-calendar__month-view__days .react-calendar__tile:hover,
.react-calendar--selectRange .react-calendar__tile--hover {
  background: var(--chakra-colors-greyAlpha-100);
}

.react-calendar__tile--now {
  background: var(--chakra-colors-white);
}
.react-calendar__tile--rangeBothEnds,
.react-calendar__tile--rangeBothEnds:hover {
  background: var(--chakra-colors-primary-500) !important;
  color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-base);
}

.react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds),
.react-calendar__tile--rangeStart:hover:not(
    .react-calendar__tile--rangeBothEnds
  ) {
  background: var(--chakra-colors-primary-500) !important;
  color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-2xl) var(--chakra-radii-none)
    var(--chakra-radii-none) var(--chakra-radii-2xl);
}
.react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds),
.react-calendar__tile--rangeEnd:hover:not(
    .react-calendar__tile--rangeBothEnds
  ) {
  background: var(--chakra-colors-primary-500) !important;
  color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-none) var(--chakra-radii-2xl)
    var(--chakra-radii-2xl) var(--chakra-radii-none);
}

.react-calendar__tile--active.react-calendar__tile--range:not(
    .react-calendar__tile--rangeBothEnds
  ):not(.react-calendar__tile--rangeStart):not(
    .react-calendar__tile--rangeEnd
  ) {
  background: var(--chakra-colors-primary-100);
  color: var(--chakra-colors-primary-500);
}

.react-calendar__tile--hoverStart {
  border-radius: var(--chakra-radii-2xl) var(--chakra-radii-none)
    var(--chakra-radii-none) var(--chakra-radii-2xl);
}

.react-calendar__tile--hoverEnd {
  border-radius: var(--chakra-radii-none) var(--chakra-radii-2xl)
    var(--chakra-radii-2xl) var(--chakra-radii-none);
}

/*
MONTHS DESIGN
*/
.react-calendar__year-view__months {
  padding: 0px var(--chakra-space-4) var(--chakra-space-4);
}
.react-calendar__tile--hasActive.react-calendar__year-view__months__month,
.react-calendar__tile--active.react-calendar__year-view__months__month {
  background-color: var(--chakra-colors-primary-500);
  color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-base);
}
.react-calendar__tile--hasActive.react-calendar__year-view__months__month:hover.react-calendar__tile:enabled:hover {
  background-color: var(--chakra-colors-primary-500);
}
.react-calendar__tile.react-calendar__year-view__months__month {
  font-size: var(--chakra-fontSizes-sm);
  padding: var(--chakra-space-2) var(--chakra-space-1);
}

.react-calendar__year-view__months__month:hover.react-calendar__tile:enabled:hover {
  background: var(--chakra-colors-greyAlpha-100);
  border-radius: var(--chakra-radii-base);
}
.react-calendar__year-view__months__month.react-calendar__tile--hoverEnd,
.react-calendar__year-view__months__month.react-calendar__tile--hoverStart {
  background-color: var(--chakra-colors-primary-100);
  color: var(--chakra-colors-primary-500);
}

.react-calendar--doubleView .react-calendar__viewContainer,
.react-calendar--doubleView .react-calendar__viewContainer > * {
  margin: 0;
}
